import { BrokerService } from './../../services/felixApi/broker.service';
import { AuthService } from './../../services/auth.service';
import { GlobalService } from './../../services/global.service';
import { UtilsService } from './../../services/utils.service';
import { LandHoldCategory } from './../../dtos/land-hold-category';
import { EstateService } from './../../services/felixApi/estate.service';
import { LandService } from '../../services/felixApi/land.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { GridService } from '../../services/grid.service';
import { DxColumn, DxGridState } from '../../dtos/dx-grid';
import { StateStoreService } from '../../services/felixApi/state-store.service';
import { LandMaster } from '../../dtos/land-master';
import { DeveloperService } from '../../services/felixApi/developer.service';
import { Developer } from '../../dtos/developer';
import { Estate } from '../../dtos/estate';
import { LandFieldTypeEnum } from '../../dtos/land-field-type.enum';
import { LandFieldLookup } from '../../dtos/land-field-lookup';
import { LandField } from '../../dtos/land-field';
import { UserService } from '../../services/felixApi/user.service';
import { User } from '../../dtos/user';
import { LandMasterField } from '../../dtos/land-master-field';
import { LandHoldWarningsComponent } from '../land-hold-warnings/land-hold-warnings.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { SetColourComponent } from '../../shared/set-colour/set-colour.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { TrackingColourEnum } from '../../dtos/tracking-colour.enum';
import { JobTask } from '../../dtos/job-task';
import { TaskMaster } from '../../dtos/task-master';

@Component({
  selector: 'js-land-masters',
  templateUrl: './land-masters.component.html',
  styleUrls: ['./land-masters.component.scss']
})
export class LandMastersComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  loadingData = true;
  brokerId: number;
  dataSource: DataSource;
  gridHeight: number;

  gridColumns: any[] = [];
  lastColSortDirectionAscending: boolean;
  reportGridData: any[];
  landMasters: LandMaster[] = [];
  developers: Developer[];
  estates: Estate[];
  landFields: LandField[] = [];
  landFieldLookups: LandFieldLookup[] = [];
  dataFieldForEdit: any;
  landHoldCategories: LandHoldCategory[] = [];
  adhocSelection: string;
  lookupList: LandFieldLookup[] = [];
  adhocItem: LandFieldLookup = null;
  users: User[];
  landMasterFields: LandMasterField[];
  landAdmin: boolean;
  jobTasksForLand: JobTask[];
  taskMasters: TaskMaster[];

  constructor(
    private landService: LandService,
    private notiService: NotificationService,
    protected gridService: GridService,
    private stateStoreService: StateStoreService,
    private developerService: DeveloperService,
    private estateService: EstateService,
    private utilService: UtilsService,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private userService: UserService,
    private authService: AuthService,
    private brokerService: BrokerService
  ) {
    this.initNewRow = this.initNewRow.bind(this);
    this.onRowPrepared = this.onRowPrepared.bind(this);
  }

  ngOnInit() {
    // in case refresh we need to reset these
    if (this.globalService.userTypeId === UserTypeEnum.Admin || this.globalService.userTypeId === UserTypeEnum.SuperUser) {
      this.globalService.landAdmin = true;
    } else {
      const maintPerm = this.authService.areaPermissions.find(i => i.applicationArea === 'LandDatabase');
      if (maintPerm?.permissionType === 'Admin') {
        this.globalService.landAdmin = true;
      }
    }

    this.landAdmin = this.globalService.landAdmin;
    this.setHeightWidths();
    this.getStateStoreForLand();

    this.subscriptions.push(
      this.globalService.innerHeightWidthChanged.subscribe(
        () => {
          setTimeout(() => {
            this.setHeightWidths();
          }, 200); // wait for iPhone and grid
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  setHeightWidths() {
    this.gridHeight = window.innerHeight - 70;
  }

  getStateStoreForLand() {
    this.subscriptions = this.subscriptions.concat(
      this.stateStoreService.getStateStoresForLand()
        .subscribe({
          next: (stateStore) => {
            if (stateStore) {
              const storedState = JSON.parse(stateStore.stateString) as DxGridState;
              localStorage.removeItem('land-database');
              localStorage.setItem('land-database', JSON.stringify(storedState));
            }
            this.getData();
          },
          error: () => {
            // assume no state stored
            this.getData();
          }
        })
    );
  }

  getData() {
    this.subscriptions = this.subscriptions.concat(
      this.landService.getLandData()
        .subscribe({
          next: () => {
            this.developers = this.developerService.developers;
            this.estates = this.estateService.estates;
            this.landFields = this.landService.landFields;
            this.landFieldLookups = this.landService.landFieldLookups;
            this.landHoldCategories = this.landService.landHoldCategories;
            this.users = this.userService.users;
            this.taskMasters = this.brokerService.taskMasters;
            this.getLandMasters(true);
          },
          error: () => {
            this.loadingData = false;
          }
        })
    );
  }

  getLandMasters(setupColumns: boolean) {
    this.subscriptions = this.subscriptions.concat(
      this.landService.getLandMastersWithFields()
        .subscribe({
          next: (landMasters) => {
            this.landMasters = landMasters;
            this.landMasterFields = this.landService.landMasterFields;
            this.jobTasksForLand = this.landService.jobTasksForLand;
            this.setupColumns(setupColumns);
          },
          error: () => {
            this.loadingData = false;
          }
        })
    );
  }

  setupColumns(setupColumns: boolean) {
    if (setupColumns) {
      let state: DxGridState;
      const stateStr = localStorage.getItem('land-database');
      if (stateStr) {
        state = JSON.parse(stateStr) as DxGridState;
      }

      this.gridColumns = [];

      // default cols //
      this.addToColumns(state, false, 'id', 'Land ID', 'number', '', 'center');
      this.addToColumns(state, true, 'landNumber', 'Land Number', 'string', '', 'center');
      this.addToColumns(state, true, 'lotNumber', 'Lot Number', 'string', '', 'left');
      this.addToColumns(state, false, 'streetNumber', 'Street Number', 'string', '', 'left');
      this.addToColumns(state, true, 'streetName1', 'Street Name', 'string', '', 'left');
      this.addToColumns(state, true, 'suburb', 'Suburb', 'string', '', 'left');
      this.addToColumns(state, false, 'state', 'State', 'string', '', 'left');
      this.addToColumns(state, false, 'postCode', 'Postcode', 'string', '', 'left');
      this.addToColumns(state, true, 'developerId', 'Developer', 'number', '', 'left', this.developers, 'description', 'id');
      this.addToColumns(state, true, 'estateId', 'Estate', 'number', '', 'left', this.estates, 'name', 'id');
      this.addToColumns(state, false, 'latitude', 'Latitude', 'string', '', 'left');
      this.addToColumns(state, false, 'longitude', 'Longitude', 'string', '', 'left');
      this.addToColumns(state, true, 'lotSize', 'Lot Size', 'number', '#,###.#', 'center');
      this.addToColumns(state, true, 'lotWidth', 'Lot Width', 'number', '#,###.#', 'center');
      this.addToColumns(state, true, 'lotDepth', 'Lot Depth', 'number', '#,###.#', 'center');
      this.addToColumns(state, true, 'price', 'Purchase Price', 'number', '#,###', 'right');
      this.addToColumns(state, true, 'landHoldCategoryId', 'Land Hold Category', 'number', '', 'left', this.landHoldCategories, 'description', 'id');
      this.addToColumns(state, true, 'landHoldExpiry', 'Land Hold Expiry', 'date', 'd-MMM-yy', 'center');
      this.addToColumns(state, true, 'landHoldComment', 'Land Hold Comment', 'string', '', 'left');
      this.addToColumns(state, true, 'isTitled', 'Is Titled', 'boolean', '', 'center');
      this.addToColumns(state, true, 'titleDueDate', 'Title Due Date', 'date', 'd-MMM-yy', 'center');
      this.addToColumns(state, true, 'daysFromTitleIssueToSettlement', 'Days from Title Issue to Settlement', 'number', '', 'center', null, null, null, this.setDaysFromTitleIssueToSettlement);
      this.addToColumns(state, true, 'settlementDate', 'Settlement Date', 'date', 'd-MMM-yy', 'center');
      this.addToColumns(state, true, 'isSettled', 'Is Settled', 'boolean', '', 'center');
      this.addToColumns(state, true, 'settlementComment', 'Settlement Comment', 'string', '', 'left');
      this.addToColumns(state, false, 'isActive', 'Is Active', 'boolean', '', 'center');
      this.addToColumns(state, false, 'modifiedDate', 'Modified Date', 'date', 'd-MMM-yy', 'center');
      this.addToColumns(state, false, 'modifiedUserId', 'Modified By', 'number', '', 'left', this.users, 'fullName');
      this.addToColumns(state, false, 'colourId', 'Colour', 'number', '', 'center');
      this.addToColumns(state, true, 'jobNumber', 'Job Number', 'string', '', 'left');

      // land fields
      this.landFields.forEach(landField => {
        let lookups = this.landFieldLookups.filter(l => l.landFieldId === landField.id);
        if (landField.trackingFieldTypeId === LandFieldTypeEnum.Boolean) {
          lookups = [];
          lookups.push(new LandFieldLookup(1, 'Yes', 1, landField.id));
          lookups.push(new LandFieldLookup(2, 'No', 2, landField.id));
          lookups.push(new LandFieldLookup(3, 'N/A', 3, landField.id));
        }

        let dataType = 'string';
        let format = '';

        switch (landField.trackingFieldTypeId) {
          case LandFieldTypeEnum.Number:
            dataType = 'number';
            format = '#,###';
            break;
          case LandFieldTypeEnum.Date:
            dataType = 'date';
            format = 'd-MMM-yy';
            break;
          default:
            dataType = 'string';
            break;
        }

        this.addToColumns(state, true, 'field-' + landField.id, landField.fieldName, dataType, format, 'left', lookups && lookups.length ? lookups : null, 'description', 'description');
      });

      // add task columns
      this.taskMasters.filter(i => i.isShownInLandMaster).forEach(task => {
        this.addToColumns(state, true, 'task-' + task.id, task.taskTitle, 'date', 'd-MMM-yy', 'center');
      });

      if (this.landAdmin) {
        // add button to set up land hold warnings
        this.gridColumns.push({
          type: 'buttons',
          width: 110,
          fixed: true,
          buttons: [
            {
              hint: 'Set up land hold warnings',
              icon: 'email',
              onClick: (e) => {
                e.event.stopImmediatePropagation();
                this.editLandHoldWarnings(e);
              }
            },
            {
              hint: 'Colour',
              icon: 'fill',
              onClick: (e) => {
                e.event.stopImmediatePropagation();
                this.setColour(e);
              }
            },
            {
              name: 'delete'
            }
          ]
        });
      }
    }
    this.generateGridRows();
  }

  checkStoredCol(columns: DxColumn[], colField: string, defaultVisible: boolean): [visible: boolean, visibleIndex: number] {
    if (!columns) {
      return [defaultVisible, 0];
    }
    const foundCol = columns.filter(c => c.dataField === colField);
    if (foundCol && foundCol.length === 1 && foundCol[0].visible) {
      return [true, foundCol[0].visibleIndex];
    } else if (foundCol && foundCol.length === 1) {
      return [false, foundCol[0].visibleIndex];
    } else {
      return [false, 0];
    }
  }

  generateGridRows() {
    this.reportGridData = [];

    this.landMasters.forEach(landMaster => {
      const reportGridRow = {};
      reportGridRow['id'] = landMaster.id;
      reportGridRow['landNumber'] = landMaster.landNumber;
      reportGridRow['lotNumber'] = landMaster.address?.lotNumber;
      reportGridRow['streetNumber'] = landMaster.address?.streetNumber;
      reportGridRow['streetName1'] = landMaster.address?.streetName1;
      reportGridRow['suburb'] = landMaster.address?.suburbTown;
      reportGridRow['state'] = landMaster.address?.state;
      reportGridRow['postCode'] = landMaster.address?.postCode;
      reportGridRow['latitude'] = landMaster.address?.latitude;
      reportGridRow['longitude'] = landMaster.address?.longitude;
      reportGridRow['landHoldCategoryId'] = landMaster.landHoldCategoryId;
      reportGridRow['developerId'] = landMaster.developerId;
      reportGridRow['estateId'] = landMaster.estateId;
      reportGridRow['lotSize'] = landMaster.lotSize;
      reportGridRow['lotWidth'] = landMaster.lotWidth;
      reportGridRow['lotDepth'] = landMaster.lotDepth;
      reportGridRow['price'] = landMaster.price;
      reportGridRow['landHoldExpiry'] = landMaster.landHoldExpiry;
      reportGridRow['landHoldComment'] = landMaster.landHoldComment;
      reportGridRow['isTitled'] = landMaster.isTitled;
      reportGridRow['titleDueDate'] = landMaster.titleDueDate;
      reportGridRow['isSettled'] = landMaster.isSettled;
      reportGridRow['settlementDate'] = landMaster.settlementDate;
      reportGridRow['daysFromTitleIssueToSettlement'] = landMaster.daysFromTitleIssueToSettlement;
      reportGridRow['settlementComment'] = landMaster.settlementComment;
      reportGridRow['isActive'] = landMaster.isActive;
      reportGridRow['modifiedDate'] = landMaster.modifiedDate;
      reportGridRow['modifiedUserId'] = landMaster.modifiedUserId;
      reportGridRow['colourId'] = landMaster.colourId;
      reportGridRow['jobNumber'] = landMaster.jobNumber;

      this.landFields.forEach(landField => {
        const landMasterField = this.landMasterFields.find(i => i.landId == landMaster.id && i.landFieldId === landField.id);
        if (landMasterField) {
          reportGridRow['field-' + landField.id] = landMasterField.textValue;
        }
      });

      // add task columns
      this.taskMasters.filter(i => i.isShownInLandMaster).forEach(task => {
        const jobTask = this.jobTasksForLand.find(i => i.landId === landMaster.id && i.taskMasterId === task.id);
        if (jobTask) {
          reportGridRow['task-' + task.id] = jobTask.endDate;
        }
      });

      this.reportGridData.push(reportGridRow);
    });

    this.loadingData = false;
    this.setUpDataSource();
  }

  addToColumns(
    state: any,
    defaultVisible: boolean,
    dataField: string,
    caption: string,
    dataType: string,
    format: string,
    alignment: 'left' | 'right' | 'center',
    lookupSource?: any,
    lookupDesc?: string,
    keyName?: string,
    setCellValue?: any
  ) {

    let res = this.checkStoredCol(state?.columns, dataField, defaultVisible);

    const col = {
      dataField: dataField,
      name: caption,
      caption: caption,
      dataType: dataType,
      format: format,
      alignment: alignment,
      visible: res[0],
      visibleIndex: res[1],
      allowEditing: true,
      allowFiltering: true,
      allowSorting: true,
      editorOptions: null
    };

    if (dataType === 'date') {
      col['sortingMethod'] = this.dateSort;
    }

    if (lookupSource) {
      col['lookup'] = {
        dataSource: [...lookupSource],
        valueExpr: keyName,
        displayExpr: lookupDesc,
        searchEnabled: true
      };

      col.editorOptions = { showClearButton: true };
    }

    if (setCellValue) {
      col['setCellValue'] = setCellValue;
    }

    if (dataField === 'landNumber' || dataField === 'lotNumber' || dataField === 'streetNumber' || dataField === 'streetName1' || dataField === 'suburb') {
      col['fixed'] = true;
    }

    this.gridColumns.push(col);
  }

  dateSort = (one, two): number => {
    if (!one && !two) { return 0; }
    if (!one && two) {
      return this.lastColSortDirectionAscending ? 1 : -1;
    }
    if (one && !two) {
      return this.lastColSortDirectionAscending ? -1 : 1;
    }
    if (one < two) { return -1; }
    if (one > two) { return 1; }
    return 0;
  }

  gridOptionChanged(e) {
    if (e.value === 'asc') {
      this.lastColSortDirectionAscending = true;
    } else if (e.value === 'desc') {
      this.lastColSortDirectionAscending = false;
    }
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: () => this.reportGridData,
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.landService.addLandMaster(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        const fieldKeys = Object.keys(values);
        let masterUpdated = false;
        const reportGridData = this.reportGridData.find(i => i.id === key);

        return new Promise((resolve, reject) =>
          fieldKeys.forEach(fieldKey => {
            if (fieldKey.substring(0, 5) === 'field') {
              const fieldId = fieldKey.substring(6);
              // if date convert to date
              if (values[fieldKey] && this.landFields.find(i => i.id === +fieldId).trackingFieldTypeId === LandFieldTypeEnum.Date) {
                values[fieldKey] = new Date(Date.parse(values[fieldKey])).getFullYear() + '-' + (new Date(Date.parse(values[fieldKey])).getMonth() + 1) + '-' + new Date(Date.parse(values[fieldKey])).getDate();
              }
              this.landService.updateLandMasterField(key, fieldId, { textValue: values[fieldKey] }).subscribe({
                next: (res) => {
                  reportGridData[fieldKey] = values[fieldKey];
                  return resolve(reportGridData);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
            } else if (!masterUpdated) {
              masterUpdated = true;
              this.landService.updateLandMaster(encodeURIComponent(key), values).subscribe({
                next: (res) => {
                  reportGridData[fieldKey] = values[fieldKey];
                  return resolve(reportGridData);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              });
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.landService.deleteLandMaster(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;

    if (this.landAdmin) {
      toolbarItems.unshift(
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            text: 'Save Layout',
            onClick: this.saveState.bind(this)
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            text: 'Reset Layout',
            onClick: this.resetState.bind(this)
          }
        }
      );
    }
  }

  onRowPrepared(e) {
    if (e.rowType === 'data') {
      if (e.data.colourId) {
        // e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
        if (e.data.colourId === TrackingColourEnum.Orange) {
          e.rowElement.style.backgroundColor = 'rgb(253, 209, 127, 0.6)';
        } else if (e.data.colourId === TrackingColourEnum.Yellow) {
          e.rowElement.style.backgroundColor = 'rgb(253, 253, 139, 0.6)';
        } else if (e.data.colourId === TrackingColourEnum.Teal) {
          e.rowElement.style.backgroundColor = 'rgb(132, 247, 237, 0.6)';
        } else if (e.data.colourId === TrackingColourEnum.Blue) {
          e.rowElement.style.backgroundColor = 'rgb(147, 198, 247, 0.4)';
        } else if (e.data.colourId === TrackingColourEnum.Magenta) {
          e.rowElement.style.backgroundColor = 'rgb(208, 157, 250, 0.6)';
        } else if (e.data.colourId === TrackingColourEnum.Grey) {
          e.rowElement.style.backgroundColor = 'rgb(0, 0, 0, 0.2)';
        }
      }
    }
  }

  saveState() {
    const currentState = JSON.parse(localStorage.getItem('land-database'));
    if (currentState) {
      const dataRecord = {
        stateString: JSON.stringify(currentState)
      };
      this.subscriptions = this.subscriptions.concat(
        this.stateStoreService.updateLandStateStore(dataRecord, 'land-database')
          .subscribe({
            next: () => {
              this.notiService.showSuccess('State saved');
            },
            error: (error) => {
              this.notiService.showError(error);
            }
          })
      );
    }
  }

  resetState() {
    localStorage.removeItem('land-database');
    this.loadingData = true;
    setTimeout(() => {
      this.setupColumns(true);
    }, 300);
  }

  onLookupValueChanged(e) {
    this.adhocSelection = e.value;
    this.dataFieldForEdit.setValue(this.adhocSelection);
  }

  onDateValueChanged(e) {
    if (e.value instanceof Date) {
      this.adhocSelection = this.utilService.convertDateToString(e.value);
    } else {
      this.adhocSelection = e.value;
    }
    this.dataFieldForEdit.setValue(this.adhocSelection);
  }

  editLandHoldWarnings(e) {
    const modalRef = this.modalService.open(LandHoldWarningsComponent, { windowClass: 'modal-1000' });
    modalRef.componentInstance.landId = e.row.data.id;

    modalRef.result.then(() => {
    }, () => { });
  }

  setColour(e) {
    // set the tracking colour for the task grids
    const rowIndex = this.grid.instance.getRowIndexByKey(e.row.data.id);

    const modalRef = this.modalService.open(SetColourComponent, { windowClass: 'modal-1000' });
    modalRef.result.then(colourId => {
      this.grid.instance.cellValue(rowIndex, 'colourId', colourId);
      this.grid.instance.saveEditData();
      this.grid.instance.refresh();
    }, () => { });
  }

  initNewRow(e) {
    e.data.isActive = true;
    e.data.isTitled = false;
    e.data.isSettled = false;

    if (!this.landMasters || this.landMasters.length === 0) {
      e.data.landNumber = 'L0001';
    } else {
      const landNumbers = this.landMasters.map(i => i.landNumber);

      // get land number without leading characters
      const maxLandNumber = Math.max(...landNumbers.map(i => parseInt(i.replace(/\D/g, ''), 10)));
      e.data.landNumber = 'L' + (maxLandNumber + 1);
    }
    this.notiService.showInfo('Add address details first then save before adding remaining data');
  }

  setDaysFromTitleIssueToSettlement(rowData: any, value, originalData) {
    rowData.daysFromTitleIssueToSettlement = value;

    // calc settleement date
    if (rowData.titleDueDate || originalData.titleDueDate) {
      const titleDueDate = new Date(rowData.titleDueDate ?? originalData.titleDueDate);
      if (titleDueDate) {
        const settleDate = new Date(titleDueDate);
        settleDate.setDate(settleDate.getDate() + value);
        rowData.settlementDate = settleDate;
      }
    }
  }
}

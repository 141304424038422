import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { HttpService } from '../http.service';
import { LandField } from '../../dtos/land-field';
import { environment } from '../../../environments/environment';
import { LandFieldLookup } from '../../dtos/land-field-lookup';
import { LandHoldCategory } from '../../dtos/land-hold-category';
import { LandMaster } from '../../dtos/land-master';
import { DeveloperService } from './developer.service';
import { EstateService } from './estate.service';
import { UserService } from './user.service';
import { LandMasterField } from '../../dtos/land-master-field';
import { LandHoldWarning } from '../../dtos/land-hold-warning';
import { JobTask } from '../../dtos/job-task';
import { BrokerService } from './broker.service';

@Injectable({
  providedIn: 'root'
})
export class LandService {

  landFieldsURL = environment.apiUrl + '/land-fields';
  landFields: LandField[] = [];
  landFieldsCompany: string;
  landFieldLookups: LandFieldLookup[] = [];
  landHoldCategories: LandHoldCategory[] = [];
  landMasterFields: LandMasterField[];
  jobTasksForLand: JobTask[];

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService,
    private developerService: DeveloperService,
    private estateService: EstateService,
    private brokerService: BrokerService,
    private userService: UserService) { }

  getLandFields(useCache: boolean): Observable<LandField[]> {
    if (useCache && this.landFields && this.landFields.length
      && this.landFieldsCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.landFields);
    } else {
      return this.http.get<LandField[]>(this.landFieldsURL, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.landFields = res;
          this.landFieldsCompany = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.globalService.handleError));
    }
  }

  addLandField(dataRecord: any): Observable<LandField> {
    return this.http.post<LandField>(this.landFieldsURL, JSON.stringify(dataRecord, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updateLandField(id: string, itm: any): Observable<LandField> {
    const url = this.landFieldsURL + '/' + id;
    return this.http.patch<LandField>(url, JSON.stringify(itm, this.globalService.jsonReplacer), this.httpService.getHttpOptions());
  }

  deleteLandField(id: string) {
    const url = this.landFieldsURL + '/' + id;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }




  getLandFieldLookups(landFieldId: number): Observable<LandFieldLookup[]> {
    let url = this.globalService.getApiUrl() + '/land-field-lookups';
    if (landFieldId) {
      url += '/' + landFieldId;
    }
    return this.http.get<LandFieldLookup[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.landFieldLookups = res;
      }),
      catchError(this.globalService.handleError));
  }

  addLandFieldLookup(dataRecord: any): Observable<LandFieldLookup> {
    const url = this.globalService.getApiUrl() + '/land-field-lookups';
    return this.http.post<LandFieldLookup>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateLandFieldLookup(id: string, itm: any): Observable<LandFieldLookup> {
    const url = this.globalService.getApiUrl() + '/land-field-lookups/' + id;
    return this.http.patch<LandFieldLookup>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteLandFieldLookup(id: string) {
    const url = this.globalService.getApiUrl() + '/land-field-lookups/' + id;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }




  getLandHoldCategories(): Observable<LandHoldCategory[]> {
    return this.http.get<LandHoldCategory[]>(this.globalService.getApiUrl() +
      '/land-hold-categories', this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.landHoldCategories = res;
        }),
        catchError(this.globalService.handleError));
  }

  addLandHoldCategory(dataRecord: any): Observable<LandHoldCategory> {
    const url = this.globalService.getApiUrl() + '/land-hold-categories';
    return this.http.post<LandHoldCategory>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateLandHoldCategory(id: string, itm: any): Observable<LandHoldCategory> {
    const url = this.globalService.getApiUrl() + '/land-hold-categories/' + id;
    return this.http.patch<LandHoldCategory>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteLandHoldCategory(id: string) {
    const url = this.globalService.getApiUrl() + '/land-hold-categories/' + id;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }


  getLandData() {
    return forkJoin(
      [
        this.getLandFields(false),
        this.getLandFieldLookups(null),
        this.getLandHoldCategories(),
        this.developerService.getDevelopers(false),
        this.estateService.getEstates(false),
        this.userService.getCurrCompUsers(),
        this.brokerService.getTaskMasters(false)
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  getLandMastersWithFields() {
    return forkJoin(
      [
        this.getLandMasters(),
        this.getLandMasterFields(),
        this.getJobTasksForLand()
      ]
    )
      .pipe(map(
        ([dataRecords]) => {
          return dataRecords;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }




  getLandMasters(): Observable<LandMaster[]> {
    return this.http.get<LandMaster[]>(this.globalService.getApiUrl() +
      '/land-masters', this.httpService.getHttpOptions()).pipe(
        catchError(this.globalService.handleError));
  }

  addLandMaster(dataRecord: any): Observable<LandMaster> {
    const url = this.globalService.getApiUrl() + '/land-masters';
    return this.http.post<LandMaster>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateLandMaster(id: string, itm: any): Observable<LandMaster> {
    const url = this.globalService.getApiUrl() + '/land-masters/' + id;
    return this.http.patch<LandMaster>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteLandMaster(id: string) {
    const url = this.globalService.getApiUrl() + '/land-masters/' + id;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }




  getLandMasterFields(): Observable<LandMasterField[]> {
    return this.http.get<LandMasterField[]>(this.globalService.getApiUrl() +
      '/land-master-fields', this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.landMasterFields = res;
        }),
        catchError(this.globalService.handleError));
  }

  updateLandMasterField(landId: string, landFieldId: string, itm: any): Observable<LandMasterField> {
    const url = this.globalService.getApiUrl() + '/land-master-fields/' + landId + '/field/' + landFieldId;
    return this.http.patch<LandMasterField>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }




  getLandHoldWarnings(landId: number): Observable<LandHoldWarning[]> {
    return this.http.get<LandHoldWarning[]>(this.globalService.getApiUrl() +
      '/land-hold-warnings?landId=' + landId, this.httpService.getHttpOptions()).pipe(
        catchError(this.globalService.handleError));
  }

  addLandHoldWarning(dataRecord: any): Observable<LandHoldWarning> {
    const url = this.globalService.getApiUrl() + '/land-hold-warnings';
    return this.http.post<LandHoldWarning>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateLandHoldWarning(id: string, itm: any): Observable<LandHoldWarning> {
    const url = this.globalService.getApiUrl() + '/land-hold-warnings/' + id;
    return this.http.patch<LandHoldWarning>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteLandHoldWarning(id: string) {
    const url = this.globalService.getApiUrl() + '/land-hold-warnings/' + id;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }



  getJobTasksForLand(): Observable<JobTask[]> {
    return this.http.get<JobTask[]>(this.globalService.getApiUrl() + '/land-masters/tasks',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.jobTasksForLand = res;
        }),
        catchError(this.globalService.handleError));
  }
}

export const APP_NAME = 'truthenginepackages';
export const APP_ID = 8;

export const MAJOR_VERSION = '1.5';
export const PATCH_VERSION = '.4a'; // not stored in DB or checked in refresh guard

/// B2C / MSAL ///
export const B2C_AUTHORITY = 'https://truthengine.b2clogin.com/truthengine.onmicrosoft.com/B2C_1_signup-signin-prod';
export const B2C_AUTHORITY_DEV = 'https://truthengine.b2clogin.com/truthengine.onmicrosoft.com/B2C_1_signup-signin-dev';
export const B2C_AUTHORITY_DOMAIN = 'truthengine.b2clogin.com';

export const GoogleMapsKey = 'AIzaSyB5-mkvwwfIsljamfYLLwm_fX50wZR9UcI'; // gerard.hart@truthengine.com.au google maps https://console.cloud.google.com/google/maps-apis/credentials?project=truthengine-402706
